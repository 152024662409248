import './App.scss';
import radio_render from "./basic.png"
import diagram from "./diagram.svg"

function Card({title, children, ...props}) {
    return (
        <div className="card" {...props}>
              <h2 className="card__title">
                  {title}
              </h2>
              <p className="card__text">
                  {children}
              </p>
          </div>
    )
}

function App() {
  return (
      <>
          <div className="header">
              <h1>Foxhunter</h1>
              <p>Waveform Deepfaking Radio</p>
          </div>
          <div className="nuggets">
              <div className="nugget">
                  <object type="image/svg+xml" data="/trl.svg"></object>
                  TRL 3
              </div>
          </div>

          <div className="radio_render__container">
              <img className="radio_render" src={radio_render}/>
          </div>
          
          <div className="foxai__container">
              <div className="foxai">
                  <div className="foxai__heading">FoxAI</div>
                  <div className="foxai__subtext">Generative AI Waveform</div>
              </div>
              
          </div>
          <Card title="Counter-AI LPI/LPD" id="card1">
              Using the <b>same underlying technology behind deepfakes, clone your adversary’s waveforms</b>. Deceive their classification systems and EW personnel into leaving your communications alone.
          </Card>

          <video controls id="first-video" >
              <source src="/firstvid.mp4" type='video/mp4' />
              <source src="/firstvid.webm" type='video/webm'/>
              <track default kind="captions" srcLang="en" src="/firstvidcaptions.vtt" />
          </video>
          <Card title="Edge AI + Software-Defined Radio" id="card2">
              Foxhunter is a software-first, integrated, hardware-agnostic signal processing solution, built for man portable, attritable, and low-SWaP-C radios

              Foxhunter is designed to expose a multitude of RF capabilities and integrate into existing systems
          </Card>
          <object type="image/svg+xml" id="diagram" data={diagram}></object>
          <Card title="TAK" id="atak">
              Our software runs on the Android platform, and our engineers have experience developing ATAK plugins
          </Card>
          <Card title="EWPMT" id="ewpmt">
              We recognize the need for central EW C2, and are prepared to integrate our system into EWPMT
          </Card>
          <Card title="Your System" id="other">
              Our engineers stand ready to integrate our radio and waveform components into the platform of your choosing
          </Card>
          <Card title="Warfighters First" id="warfighters-first">
              Foxhunter is designed to expose a multitude of RF capabilities and integrate into existing systems, to reduce distractions, training, and weight burdens on the warfighter. Our team is dedicated to our end-users.
              <br/><br/>
              Dhruv, DSP Engineer & Sales [<a href="mailto:dhruv@foxhunter.ai">dhruv@foxhunter.ai</a>]<br/>
              Troy, Chief Engineer & Hiring Manager [<a href="mailto:troy@foxhunter.ai">troy@foxhunter.ai</a>]<br/>
          </Card>
      </>
  );
}

export default App;
